import axios from "axios";
import { Message } from "element-ui";

// axios.defaults.baseURL = "http://192.168.101.14:1235/webapi/index/api"; // 设置所有请求的前半部分
// axios.defaults.baseURL = "https://sapi.23xbl.cn/webapi/index/api"; // 设置所有请求的前半部分
 axios.defaults.baseURL = "https://zapi.23xbl.cn/webapi/index/api"; // 设置所有请求的前半部分
// axios.defaults.baseURL = "https://www.noreq.top/webapi/index/api"; //测试环境
axios.defaults.headers["Content-Type"] = "application/json";

// 添加请求拦截器
axios.interceptors.request.use(
    (config) => {
        const params = {
            "app_id": "10101",
            "app_key": "XSLBXMHCYZ",
            "app_code": "XSLBXGVRKEMGNSZ",
            "timestamp": +new Date(),
            "ver": "v1.0.0",
            'sign': "123456",
            data: {
                ...config.data,
            }
        }
        params.func = config.data.func
        if (config.data.func) {
            delete config.data.func;
        }
        params.data = config.data
        config.data = params
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// 添加响应拦截器
axios.interceptors.response.use(
    (res) => {
        // 判断登录状态
        let ress = res.data.data
        if (ress.flag === 0 && (ress.data.code === 4000 || ress.data.code === 4001)) {
            Message.error(ress.errmsg);
            setTimeout(() => {
                // router.push("/");
                localStorage.clear();
                window.location.href = "/";
            }, 1500);
        }
        return res;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default axios;
